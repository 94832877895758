// React
import React, { useEffect } from 'react';

// Gatsby
import { graphql } from 'gatsby';

// Emotion / styling
import styled from '@emotion/styled';
import s from 'src/styles';

// Packages
import { useGlobal } from 'reactn';

// Components
import SEO from 'components/seo';
import StoryHero from 'components/_stories/storyHero';
import StoryContent from 'components/_stories/storyContent';
import RelatedProducts from 'components/_shop/relatedProducts';
import RelatedStories from 'components/_stories/relatedStories';
import Subscription from 'components/subscription';
import Newsletter from 'components/newsletter';

const StoryPageComponent = props => {
    const {
        location,
        pageContext,
        pageContext: { locale },
        data: {
            datoCmsBlogPost,
            datoCmsBlogPost: {
                seoMetaTags,
                relatedPosts,
                relatedProducts,
                showSubscription = false,
                showNewsletter = false,
            },
            datoCmsPageStory,
        },
    } = props;

    // Set theme
    const [pageTheme, setPageTheme] = useGlobal('pageTheme');
    const [headerTheme, setHeaderTheme] = useGlobal('headerTheme');
    useEffect(() => {
        setPageTheme(s.themeColor({ color: 'categories', index: 2 }));
        setHeaderTheme(s.themeColor({ color: 'categories', index: 1 }));
    }, []);

    return (
        <>
            <s.layout.PageContainer>
                <SEO {...{ seoMetaTags, locale }} />
                <ContentWrapper>
                    <StoryHero
                        {...{
                            theme: headerTheme,
                            pageContext,
                            location,
                            story: datoCmsBlogPost,
                            stories: datoCmsPageStory,
                        }}
                    />
                    <StoryContent
                        {...{
                            theme: pageTheme,
                            pageContext,
                            story: datoCmsBlogPost,
                        }}
                    />
                    {relatedProducts.length > 0 && (
                        <RelatedProducts
                            {...{
                                pageContext,
                                location,
                                products: relatedProducts,
                            }}
                        />
                    )}
                    {relatedPosts.length > 0 && (
                        <RelatedStories
                            {...{
                                pageContext,
                                location,
                                stories: relatedPosts,
                            }}
                        />
                    )}
                    {showNewsletter && (
                        <Newsletter
                            {...{
                                pageContext,
                                location,
                            }}
                        />
                    )}
                    {showSubscription && (
                        <Subscription
                            {...{
                                pageContext,
                                location,
                            }}
                        />
                    )}
                </ContentWrapper>
            </s.layout.PageContainer>
        </>
    );
};

export default StoryPageComponent;

export const storyPageQuery = graphql`
    query StoryPageQuery($itemId: String!) {
        datoCmsBlogPost(id: { eq: $itemId }) {
            ...BlogPostLong
        }
        datoCmsPageStory {
            slug
        }
    }
`;

const ContentWrapper = styled.div``;

// React
import React from 'react';

// Emotion / styling
import styled from '@emotion/styled';
import s from 'src/styles';

// Packages
import { useGlobal } from 'reactn';

// Utilities
import { TransitionLink } from 'transition';

// Components
import Image from 'components/image';
import Arrow from 'components/arrow';

const StoryHeroComponent = ({
    story,
    stories,
    pageContext,
    theme,
    location,
}) => {
    const { labels } = pageContext;
    const { title, introduction, thumbnail, thumbnailCaption } = story;

    const [prevLocation] = useGlobal('prevLocation');

    return (
        <>
            <StoryHero>
                <ArrowWrapper>
                    <TransitionLink
                        from={location}
                        to={
                            prevLocation
                                ? prevLocation.pathname
                                : `/${stories.slug}/`
                        }
                        title={labels.PAGES.PRODUCT.BACK}
                        transition={[
                            'default',
                            {
                                theme,
                            },
                        ]}>
                        <Arrow {...{ theme, hover: true, direction: 'left' }} />
                    </TransitionLink>
                </ArrowWrapper>
                <Content>
                    <Heading>{title}</Heading>
                    {introduction && (
                        <Introduction>{introduction}</Introduction>
                    )}
                    {thumbnail && (
                        <ImageWrapper>
                            <Image
                                {...{
                                    source: thumbnail.fluid,
                                    alt: thumbnailCaption || title,
                                }}
                            />
                        </ImageWrapper>
                    )}
                    {thumbnail && thumbnailCaption && (
                        <Caption>{thumbnailCaption}</Caption>
                    )}
                </Content>
            </StoryHero>
        </>
    );
};

const StoryHero = styled.div`
    ${s.responsive.property('padding-top', {
        0: 170,
        30: 230,
    })};

    position: relative;
    display: flex;
    flex-direction: column;
    color: ${s.color('black').default()};
`;

const ArrowWrapper = styled.div`
    position: relative;
    width: 50px;
    margin-top: -80px;
    margin-bottom: 20px;
    cursor: pointer;
    ${s.responsive.mq(20)} {
        margin-top: -18px;
        margin-bottom: -42px;
    }
`;

const Content = styled.div`
    ${s.responsive.property('margin-top', {
        0: '0em',
        20: '-1em',
    })};

    ${s.grid.columns('margin-left', {
        0: [0, 0],
        20: [1, 0],
    })};
`;

const Heading = styled.div`
    margin-bottom: 0.3em;
    ${s.fonts.style('h2')};
    ${s.grid.columns('width', {
        0: 4,
        20: 5,
        30: 9,
    })};
`;

const Introduction = styled.div`
    margin-bottom: 1.2em;
    ${s.fonts.style('h8')};
    ${s.grid.columns('width', {
        0: 4,
        20: 5,
        30: 9,
    })};
`;

const ImageWrapper = styled.div``;

const Caption = styled.div`
    margin-top: 1.5em;
    ${s.fonts.style('h18')};
    ${s.grid.columns('width', {
        0: 4,
        20: 5,
        30: 9,
    })};
`;

export default StoryHeroComponent;

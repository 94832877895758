// React
import React from 'react';

// Emotion / styling
import styled from '@emotion/styled';
import s from 'src/styles';

// Packages

// Utilities

// Components
import Image from 'components/image';
import StoryText from './components/storyText';
import StoryImage from './components/storyImage';
import StoryImageWide from './components/storyImageWide';
import StoryQuote from './components/storyQuote';
import StoryVideo from './components/storyVideo';

const contentTypes = {
    DatoCmsBlogpostText(key, props) {
        return <StoryText key={key} {...props} />;
    },
    DatoCmsBlogpostImage(key, props) {
        return <StoryImage key={key} {...props} />;
    },
    DatoCmsBlogpostImageWide(key, props) {
        return <StoryImageWide key={key} {...props} />;
    },
    DatoCmsBlogpostQuote(key, props) {
        return <StoryQuote key={key} {...props} />;
    },
    DatoCmsBlogpostVideo(key, props) {
        return <StoryVideo key={key} {...props} />;
    },
};

const StoryContentComponent = ({ theme, story, pageContext }) => {
    const { content, author, date } = story;

    return (
        <>
            <StoryContent>
                <Author>
                    <AuthorImage>
                        <Image
                            {...{
                                source: author.image.fluid,
                                alt: author.name,
                            }}
                        />
                    </AuthorImage>
                    <AuthorContent>
                        <span>{date}</span>
                        <span>{author.name}</span>
                    </AuthorContent>
                </Author>
                <ContentWrapper>
                    {content.map((item, index) =>
                        contentTypes[item.__typename](
                            `${item.__typename}-${index}`,
                            {
                                item,
                                pageContext,
                                theme,
                            }
                        )
                    )}
                </ContentWrapper>
            </StoryContent>
        </>
    );
};

const StoryContent = styled.div`
    display: flex;
    color: ${s.color('black').default()};

    ${s.responsive.property('flex-direction', {
        0: 'column',
        30: 'row',
    })};

    ${s.responsive.property(['margin-top', 'margin-bottom'], {
        0: '4em',
        20: '5em',
        30: '9em',
    })};
`;

const Author = styled.div`
    display: flex;

    ${s.grid.columns('width', {
        0: 4,
        30: 2,
    })};

    ${s.grid.columns('margin-left', {
        0: 0,
        20: [1, 0],
    })};

    ${s.responsive.property('flex-direction', {
        0: 'row',
        30: 'column',
    })};

    ${s.responsive.property('align-items', {
        0: 'center',
        30: 'flex-start',
    })};

    ${s.responsive.property('margin-bottom', {
        0: '0em',
        20: '2em',
        30: '0em',
    })};
`;

const AuthorImage = styled.div`
    ${s.grid.columns(['width', 'height'], {
        0: 1,
        30: 1.5,
        40: 1,
    })};

    ${s.grid.gutter('margin-right', {
        0: 2,
        30: 0,
    })};

    ${s.responsive.property('margin-bottom', {
        0: '0em',
        30: '1em',
    })};

    border-radius: 100%;
    overflow: hidden;
`;

const AuthorContent = styled.div`
    span {
        display: block;
        &:first-of-type {
            ${s.fonts.style('h17')};
            margin-bottom: 0.2em;
        }
        &:last-of-type {
            ${s.fonts.style('h14')};
        }
    }
`;

const ContentWrapper = styled.div`
    ${s.responsive.property('margin-top', {
        0: '2em',
        20: '-2em',
    })};

    ${s.grid.columns('width', {
        0: 4,
        20: 5,
        30: 8,
        40: 7,
    })};

    ${s.grid.columns('margin-left', {
        0: 0,
        20: [1, 0],
        30: [0, 2],
    })};

    > div:first-of-type {
        > :first-of-type {
            margin-top: 24px !important;
        }
    }

    display: flex;
    flex-direction: column;
`;

export default StoryContentComponent;

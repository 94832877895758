// React
import React from 'react';

// Emotion / styling
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import s from 'src/styles';

// Components
import Image from 'components/image';

const StoryImageComponent = ({ item }) => {
    const { removeMargin, image, imageCaption } = item;
    return (
        <>
            <StoryImage {...{ removeMargin }}>
                <Image
                    {...{
                        source: image.fluid,
                        alt: imageCaption,
                    }}
                />
                <Caption>{imageCaption}</Caption>
            </StoryImage>
        </>
    );
};

const StoryImage = styled.div`
    display: block;
    ${props =>
        props.removeMargin &&
        css`
            ${s.responsive.property(['margin-top', 'margin-bottom'], {
                0: '1em',
            })};
        `};

    ${props =>
        !props.removeMargin &&
        css`
            ${s.responsive.property(['margin-top', 'margin-bottom'], {
                0: '3em',
                20: '4em',
                30: '5em',
                40: '6em',
            })};
        `};
`;

const Caption = styled.div`
    margin-top: 1.5em;
    ${s.fonts.style('h18')};
`;

export default StoryImageComponent;

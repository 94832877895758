// React
import React from 'react';

// Emotion / styling
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import s from 'src/styles';

// Packages
import YouTube from 'react-youtube';

const StoryVideoComponent = ({ item }) => {
    const {
        removeMargin,
        video: { height, width, providerUid },
        videoCaption,
    } = item;
    return (
        <>
            <StoryVideo {...{ removeMargin, height, width }}>
                <YouTube
                    {...{
                        videoId: providerUid,
                        opts: {
                            width,
                            height,
                            playerVars: {
                                modestbranding: true,
                                loop: 1,
                                showinfo: 0,
                            },
                        },
                    }}
                />
                {videoCaption && <Caption>{videoCaption}</Caption>}
            </StoryVideo>
        </>
    );
};

const StoryVideo = styled.div`
    display: block;

    ${props =>
        props.removeMargin &&
        css`
            ${s.responsive.property(['margin-top', 'margin-bottom'], {
                0: '2em',
            })};
        `};

    ${props =>
        !props.removeMargin &&
        css`
            ${s.responsive.property(['margin-top', 'margin-bottom'], {
                0: '3em',
                20: '4em',
                30: '5em',
                40: '6em',
            })};
        `};

    ${s.grid.columns('margin-left', {
        0: [0, 0],
        20: [-1, 0],
        30: [-2, 0],
    })};

    ${s.grid.columns('margin-right', {
        0: [0, 0],
        30: [-1, 0],
        40: [-2, 0],
    })};

    > div {
        position: relative;
        padding-bottom: ${props => `${(props.height / props.width) * 100}%`};
        width: 100%;
    }

    iframe {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;

const Caption = styled.span`
    display: block;
    margin-top: 1.5em;
    ${s.fonts.style('h18')};
`;

export default StoryVideoComponent;

// React
import React from 'react';

// Gatsby
import { useStaticQuery, graphql } from 'gatsby';

// Emotion / styling
import styled from '@emotion/styled';
import s from 'src/styles';

// Packages

// Utilities

// Components
import Story from 'components/_stories/story';

const RelatedStoriesComponent = ({
    pageContext,
    location,
    stories,
    label = null,
    parentSlug,
}) => {
    const { labels } = pageContext;

    // Story layout
    let storyLayout;
    switch (stories.length) {
        case 2:
            storyLayout = 'half';
            break;
        case 3:
            storyLayout = 'third';
            break;
        default:
            storyLayout = 'default';
            break;
    }

    return (
        <>
            <RelatedStories>
                <h3>{label ? label : labels.PAGES.STORIES.RELATED}</h3>
                <Stories>
                    {stories.map(story => (
                        <Story
                            key={story.id}
                            {...{
                                story,
                                pageContext,
                                location,
                                parentSlug,
                                storyLayout,
                            }}
                        />
                    ))}
                </Stories>
            </RelatedStories>
        </>
    );
};

const RelatedStories = styled.div`
    ${s.layout.fullWidthBackground(s.color('white').default())};
    ${s.responsive.property('padding-top', {
        0: 50,
        30: 80,
    })};

    ${s.responsive.property('padding-bottom', {
        0: 20,
    })};

    display: block;
    color: ${s.color('black').default()};

    position: relative;
    z-index: ${s.layout.zIndex.higherThanStickyCta};

    h3 {
        ${s.fonts.style('h6')};
        margin-bottom: 1.5em;
    }
`;

const Stories = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export default props => {
    const { datoCmsPageStory } = useStaticQuery(graphql`
        query {
            datoCmsPageStory {
                slug
            }
        }
    `);

    return (
        <RelatedStoriesComponent
            {...{
                parentSlug: datoCmsPageStory.slug,
                ...props,
            }}
        />
    );
};

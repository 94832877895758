// React
import React from 'react';

// Emotion / styling
import styled from '@emotion/styled';
import s from 'src/styles';

const StoryTextComponent = ({ item }) => {
    return (
        <>
            <StoryText dangerouslySetInnerHTML={{ __html: item.text }} />
        </>
    );
};

const StoryText = styled.div`
    display: block;
    color: ${s.color('white').text()};
    h1 {
        ${s.fonts.style('h7')};
        ${s.responsive.property('margin-top', {
            0: '4em',
            20: '3em',
        })};
    }

    h2 {
        ${s.fonts.style('h105')};
        font-weight: bold;
        ${s.responsive.property('margin-top', {
            0: '2em',
            20: '1em',
        })};
    }

    h3 {
        ${s.fonts.style('h10')};
        font-weight: bold;
        ${s.responsive.property('margin-top', {
            0: '2em',
            20: '1em',
        })};
    }

    p {
        ${s.responsive.property('margin-bottom', {
            0: '1em',
            20: '1em',
        })};
    }

    p,
    h4,
    h5,
    h6,
    ul,
    ol,
    li {
        ${s.fonts.style('h10')};
        a {
            text-decoration: underline;
        }
    }
`;

export default StoryTextComponent;

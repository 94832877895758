// React
import React from 'react';

// Emotion / styling
import styled from '@emotion/styled';
import s from 'src/styles';

// Components
import { ReactComponent as SvgQuote } from 'src/images/quote.svg';

const StoryQuoteComponent = ({ item, theme }) => {
    return (
        <>
            <StoryQuote {...{ theme }}>
                <blockquote dangerouslySetInnerHTML={{ __html: item.quote }} />
                <span>{item.author}</span>
                <SvgQuote />
            </StoryQuote>
        </>
    );
};

const StoryQuote = styled.div`
    ${s.grid.columns('margin-left', {
        0: [0, 0],
        20: [-1, 0],
        30: [-2, 0],
    })};

    ${s.grid.columns('margin-right', {
        0: [0, 0],
        30: [-1, 0],
        40: [-2, 0],
    })};

    ${s.responsive.property(['margin-top', 'margin-bottom'], {
        0: '3em',
        20: '4em',
        30: '5em',
        40: '6em',
    })};

    color: ${props => props.theme.default()};
    position: relative;
    display: block;

    blockquote {
        margin: 0;
        ${s.fonts.style('h4')};
    }
    span {
        display: block;
        ${s.fonts.style('h14')};
        margin-top: 1.5em;
    }
    svg {
        position: absolute;

        transform-origin: 0 0;

        ${s.responsive.property('left', {
            0: '0',
            20: '-1em',
        })};

        ${s.responsive.property('top', {
            0: '-1.5em',
            20: '-2.5em',
        })};

        ${s.responsive.property('transform', {
            0: 'scale(0.5)',
            20: 'scale(1)',
        })};
    }
`;

export default StoryQuoteComponent;

// React
import React from 'react';

// Emotion / styling
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import s from 'src/styles';

// Components
import Image from 'components/image';

const StoryImageWideComponent = ({ item }) => {
    const { removeMargin, image, imageCaption } = item;
    return (
        <>
            <StoryImageWide {...{ removeMargin }}>
                <Image
                    {...{
                        source: image.fluid,
                        alt: imageCaption,
                    }}
                />
                <Caption>{imageCaption}</Caption>
            </StoryImageWide>
        </>
    );
};

const StoryImageWide = styled.div`
    display: block;

    ${props =>
        props.removeMargin &&
        css`
            ${s.responsive.property(['margin-top', 'margin-bottom'], {
                0: '2em',
            })};
        `};

    ${props =>
        !props.removeMargin &&
        css`
            ${s.responsive.property(['margin-top', 'margin-bottom'], {
                0: '3em',
                20: '4em',
                30: '5em',
                40: '6em',
            })};
        `};

    ${s.grid.columns('margin-left', {
        0: [0, 0],
        20: [-1, 0],
        30: [-2, 0],
    })};

    ${s.grid.columns('margin-right', {
        0: [0, 0],
        30: [-1, 0],
        40: [-2, 0],
    })};
`;

const Caption = styled.div`
    margin-top: 1.5em;
    ${s.fonts.style('h18')};
    ${s.grid.columns('margin-left', {
        0: [0, 0],
        20: [1, 0],
        30: [2, 0],
    })};
`;

export default StoryImageWideComponent;
